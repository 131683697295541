import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const HorizontalScrollComponent = () => {
  const panelsContainerRef = useRef(null);
  const panelsRef = useRef([]);

  useEffect(() => {
    const panelsContainer = panelsContainerRef.current;
    const panels = panelsRef.current;

    let tween = gsap.to(panels, {
      x: () => -1 * (panelsContainer.scrollWidth - window.innerWidth),
      ease: 'none',
      scrollTrigger: {
        trigger: panelsContainer,
        pin: true,
        start: 'top top',
        scrub: 1,
        end: () => '+=' + (panelsContainer.scrollWidth - window.innerWidth),
        onUpdate: (self) => {
          console.log(self.progress);
        }
      }
    });

    document.querySelectorAll('.anchor').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        let targetElem = document.querySelector(e.target.getAttribute('href'));
        let y = targetElem;
        if (targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
          let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start;
          let totalMovement = panelsContainer.scrollWidth - window.innerWidth;
          y = Math.round(
            tween.scrollTrigger.start +
            (targetElem.offsetLeft / totalMovement) * totalScroll
          );
        }
        gsap.to(window, {
          scrollTo: {
            y: y,
            autoKill: false
          },
          duration: 1
        });
      });
    });
  }, []);

  return (
    <div id="page" className="site" style={{ margin: 0, height: '100%', fontWeight: 300, overflowX: 'hidden', zIndex: 10000 }}>
      <div id="feather" className="feather" />

      <header id="masthead" className="site-header" role="banner" style={{ position: 'fixed', display: 'flex', zIndex: 10001 }}>
        <nav className="anchor-nav" role="navigation" style={{ padding: '1rem 2rem' }}>
          <a href="#intro" className="anchor" style={{ color: 'white' }}>Home</a>
          <a href="#panel-1" className="anchor" style={{ color: 'white' }}>Panel 1</a>
          <a href="#panel-3" className="anchor" style={{ color: 'white' }}>Panel 3</a>
          <a href="#panel-5" className="anchor" style={{ color: 'white' }}>Panel 5</a>
          <a href="#map" className="anchor" style={{ color: 'white' }}>Map</a>
        </nav>
      </header>

      <main id="content" className="site-content" role="main">
        <section id="intro" className="full-screen pt-5 blue" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', background: 'blue', zIndex: 10000 }}>
          <h1 style={{ textAlign: 'center' }}>A cool title</h1>
          <div id="clouds-layer-1" className="clouds" />
          <div id="clouds-layer-2" className="clouds" />
        </section>

        <section id="panels">
          <div id="panels-container" ref={panelsContainerRef} style={{ width: '500%', height: '100vh', display: 'flex', flexWrap: 'nowrap', overflow: 'hidden', backgroundColor: '#ddd', zIndex: 10000 }}>
            {['red', 'orange', 'purple', 'green', 'gray'].map((color, index) => (
              <article
                key={index}
                id={`panel-${index + 1}`}
                ref={el => panelsRef.current[index] = el}
                className="panel full-screen"
                style={{
                  minWidth: '100vw',
                  height: '100vh',
                  display: 'flex',
                  color: '#333',
                  textAlign: 'left',
                  borderRight: '1px solid #f00',
                  backgroundColor: color,
                  zIndex: 10000
                }}
              >
                <div className="container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div className="col-6">
                    <img src="" alt="" style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />
                  </div>
                  <div className="col-6 d-flex flex-column" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <h2 style={{ textAlign: 'center' }}>Panel {index + 1}</h2>
                    <p className="step-description">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Including versions of Lorem Ipsum.
                    </p>
                    <div className="panels-navigation" style={{ position: 'absolute', width: '100%', bottom: '2rem', right: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
                      {index > 0 && (
                        <div className="nav-panel" data-sign="minus" style={{ marginRight: '2rem' }}>
                          <a href={`#panel-${index}`} className="anchor">Prev</a>
                        </div>
                      )}
                      {index < 4 && (
                        <div className="nav-panel" data-sign="plus" style={{ marginRight: '2rem' }}>
                          <a href={`#panel-${index + 2}`} className="anchor">Next</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </section>

        <section id="map" className="full-screen" style={{ height: '100vh', zIndex: 10000 }}></section>
      </main>
    </div>
  );
};

export default HorizontalScrollComponent;
