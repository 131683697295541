

import React, { useEffect } from 'react'
import '../Home/css/home.css'
import './coming.css'
const ComingSoon = ({text}) => {
  function scrollToSection() {
    const section = document.getElementById('mainnnn');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function scrollToSection1() {
    const section = document.getElementById('mainnnn');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    scrollToSection();
    scrollToSection1();
  }, [])
  return (
    <div className='comingsoon_main'>
      <div id="mainnnn"></div>
        <p style={{fontSize:'46px',marginBottom:'auto'}}>{text}</p>
        <p style={{fontSize:'46px',marginBottom:'auto'}}>Coming Soon...</p>
    </div>
  )
}

export default ComingSoon
