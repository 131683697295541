
import React from 'react'
import getImg from '../../Assets/img/contact_us_fullbar.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faXmark } from '@fortawesome/free-solid-svg-icons'
import logo from '../../Assets/img/trisanco_Logo.png'
import  {Link} from 'react-router-dom'

const FullBar = ({ setFlag }) => {
  return (
    <div className='fullbar_main_component'> 
      <div className='side_fullbar' style={{overflowX:'auto'}}>
        <div className="logo_fullbar" style={{flex:'1'}}>
          <Link to={'/'} ><img src={logo} alt='img' style={{height:'300px',width:'300px' ,position:'relative',bottom:'80px'}}/></Link>
        </div>
        <div  className="socials_fullbar" style={{marginBottom:'40px'}}>
          <p className="socials_fullbar_heading">Follow us</p>
          <a target='_blank' rel="noopener noreferrer" href={'https://www.linkedin.com/company/trisanco-infotech-pvt-ltd/'} className='socials_fullbar_socials'>Linkedin</a>
          <a target='_blank' rel="noopener noreferrer" href={'https://www.instagram.com/trisanco_infotech?igsh=MW9vOTJ2OXgzMnQ1'} className='socials_fullbar_socials'>Instagram</a>
          <a target='_blank' rel="noopener noreferrer" href={'https://www.facebook.com/share/ELW2ZpLJBqXnQStd/?mibextid=qi2Omg'} className='socials_fullbar_socials'>Facebook</a>
        </div>
        <hr />
        <div style={{flex:'1'}} className="side_linkss">
          <Link to={'/about-us'} className="links" onClick={()=>setFlag(false)}>About Us</Link>
          <Link to={'/blogs'} className="links" onClick={()=>setFlag(false)}>Blogs</Link>
          <Link to={'/contact'} className="links" onClick={()=>setFlag(false)}>Contact Us</Link>
        </div>
      </div>
      <hr />
      <div className='main_fullbar'>
        <Link to={'/'} className="links" onClick={()=>setFlag(false)}>Home</Link>
        <Link to={'/about-us'} className="links" onClick={()=>setFlag(false)}>About Us</Link>
        <Link to={'/portfolio'} className="links" onClick={()=>setFlag(false)}>Portfolio</Link>
        <Link to={'/blogs'} className="links" onClick={()=>setFlag(false)}>Blogs</Link>
        <Link to={'/contact'} className="links" onClick={()=>setFlag(false)}>Contact Us</Link>
      </div>
      <hr />
      <div className='right_fullbar'>
        <img style={{marginTop:'30px',height:'350px',width:'auto'}} src={getImg} height={350} width={250} />
        <div className="get_in_touch_fullbar">
          <p className="getInTouch_title">Get in touch</p>
          <Link to={'/'} className="getInTouch_desc">+91 07554506621</Link>
          <Link to={'/'} className="getInTouch_desc">trisancoinfotech@gmail.com</Link>
          <Link to={'/'} className="getInTouch_desc">117, Shri Ram Colony, Near SMH Hospital, Hoshangabad Road, Bhopal,Madhya Pradesh</Link>

        </div>
      </div>

      <div className='cross_circle' style={{ position: 'absolute', top: "50px", right: '50px', background: 'black', height: '80px', width: '80px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', cursor: 'pointer' }}>
        <FontAwesomeIcon onClick={() => setFlag(false)} color='white' icon={faXmark} style={{ fontSize: '40px' }} />
      </div>
    </div>
  )
}

export default FullBar
