
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  {Link} from 'react-router-dom'
import React, { useState } from 'react'
import FullBar from './FullBar'

const MobileNav = () => {
  const [flag, setFlag] = useState(false)
  return (
    <div className='mobile_nav' style={{ height: '80px', width: '100vw', background: '#001a33', color: 'white', display: 'none', alignItems: 'center', position: 'relative', zIndex: '2',scrollBehavior:'' }}>
      <Link to={'/'} className="trisanco_nav_logo" style={{ textDecoration: 'none', color: 'white', fontSize: '36px', fontWeight: '600', marginLeft: '10px' }}>TRISANCO</Link>
      <FontAwesomeIcon onClick={() => setFlag(true)} icon={faBars} style={{ fontSize: '28px', marginLeft: 'auto', marginRight: '10px', cursor: 'pointer' }} />

      {flag && <FullBar setFlag={setFlag} />}
    </div>
  )
}

export default MobileNav
