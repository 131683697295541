import React from 'react';

const ClientsSays = ({ name, location, review, imageUrl }) => {
  const containerStyle = {
    maxWidth: '94vw',
    margin: '40px auto',
    padding: '50px 20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.3)',
    position: 'relative',
    zIndex: '2',
    minWidth: '370px',
    overflow: 'hidden',
    backgroundImage: `linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.3s ease-in-out',
  };

  const overlayStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '1',
  };

  const contentStyle = {
    position: 'relative',
    zIndex: '2',
    color: '#fff',
    padding: '20px',
  };

  const headingStyle = {
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#ffd700',
    textTransform: 'uppercase',
    letterSpacing: '3px',
  };

  const testimonialContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginRight: '20px',
    border: '4px solid #fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.3s ease-in-out',
  };

  const testimonialStyle = {
    fontStyle: 'italic',
    fontSize: '20px',
    color: '#ddd',
    flex: 1,
  };

  const clientInfoStyle = {
    fontSize: '1.1rem',
    fontWeight: '500',
    color: '#ddd',
    marginTop: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  };

  const clientNameStyle = {
    color: '#ffd700',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}
    className='client_says_main'
      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
    >
      <div style={overlayStyle}></div>
      <div style={contentStyle}>
        <h2 style={headingStyle}>What Our Clients Say</h2>
        <div style={testimonialContainerStyle} className='client_says_inner_div'>
          <img
            src={imageUrl}
            alt={name}
            style={imageStyle}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          />
          <p style={testimonialStyle}>
            "{review}"
          </p>
        </div>
        <p style={clientInfoStyle}>
          <span style={clientNameStyle}>{name}</span>, {location}
        </p>
      </div>
    </div>
  );
};

export default ClientsSays;
