import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Footer from "./Components/Home/Footer";
import SideNav from "./Components/Home/SideNav";
import { useState } from "react";
import FullBar from "./Components/Home/FullBar";
import MobileNav from "./Components/Home/MobileNav";
import AboutMain from "./Components/About/AboutMain";
import PortFolio from "./Components/PortFolio/PortFolio";
import Contact from "./Components/Contact/Contact";
import Blogs from "./Components/ComingSoon/ComingSoon";
import Cursor from "./Components/GSAP/Cursor";
import { lightTheme } from "./constants/theme";
import PortFolioMain from "./Components/PortFolio/PortFolioMain";

function App() {
  const [flag, setFlag] = useState(false);
  const [theme, setTheme] = useState(lightTheme);

  return (
    <div style={{ background: "#000e2e", overflow: "hidden" }}>
      <BrowserRouter>
        <Cursor theme={theme} />
        <SideNav setFlag={setFlag} />
        <MobileNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutMain />} />
          <Route path="/portfolio" element={<PortFolioMain />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
        </Routes>
        <Footer />
        {flag && <FullBar setFlag={setFlag} />}
      </BrowserRouter>
    </div>
  );
}

export default App;
