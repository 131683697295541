import React, { useEffect } from "react";
import image from "../../Assets/img/portfolio_main.jpg";
import Clients from "../Home/Clients";
import Button from "./ui/Button";
import why_image from "../../Assets/img/why-us.jpg";
import Services from "./ui/Services";
import Reviews from "./ui/Reviews";
import { useNavigate } from "react-router-dom";
import { reviews } from "../../constants/data";
import ClientsSays from "../Home/ClientsSays";

const PortFolioMain = () => {
  const navigate = useNavigate();
  function scrollToSection() {
    const section = document.getElementById('mainnnn');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function scrollToSection1() {
    const section = document.getElementById('mainnnn');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    scrollToSection();
    scrollToSection1();
  }, [])
  return (
    <div className="portfolio_main_div">
      <div id="mainnnn"></div>
      <div className="portfolio_banner">
        <div className="portfolio_banner_content">
          <div style={{ width: "80%" }}>
            <p className="portfolio_banner_content_text">Be Focused.</p>
            <p
              className="portfolio_banner_content_text"
              style={{ color: "orange" }}
            >
              Be Determined.
            </p>
            <p style={{ color: "gray", fontWeight: "500" }}>
              We believe in the power of focus and determination. Our team is
              dedicated to delivering exceptional results, exceeding client
              expectations every step of the way.
            </p>
            <Button
              handleClick={() => navigate("/contact")}
              name={"Contact Us"}
            />
          </div>
        </div>
        <div className="portfolio_img">
          <img src={image} alt="Portfolio" />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          right: "69px",
          maxWidth: "99%",
          overflow: "hidden",
          marginTop: "50px",
        }}
        className="port_clients_div"
      >
        <Clients />
      </div>
      <div className="port_why_choose_us">
        <div className="port_why_choose_us_img">
          <img src={why_image} alt="Why Choose Us" />
        </div>
        <div>
          <div style={{ width: "90%" }}>
            <p
              className="portfolio_banner_content_text"
              style={{ color: "white" }}
            >
              Why Choose Us?
            </p>
            <p style={{ color: "gray", fontWeight: "500" }}>
              At our company, we prioritize excellence and client satisfaction.
              Our team of experts brings unparalleled expertise and a commitment
              to delivering innovative solutions tailored to your needs. We
              ensure every project is handled with the utmost professionalism,
              guaranteeing outstanding outcomes that drive success.
            </p>
            <Button name={"Read More"} />
          </div>
        </div>
      </div>
      <div>
        <Services />
      </div>
      <div>
      <div
        className="client_says client_says1 no_scrollbar"
        style={{
          position: "relative",
          zIndex: "2",
          display: "flex",
          // background: "white",
          gap: "20px",
          padding: "0",
          overflowX: "auto",
          marginRight:'75px'
        }}
      >
        {reviews.map((review, index) => (
          <ClientsSays key={index} {...review} />
        ))}
      </div>
      </div>
      <div className="port_contact_us">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default PortFolioMain;
