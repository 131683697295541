import { Link } from 'react-router-dom';
import React from 'react';
import imagee from '../../Assets/img/journal.png'
import JournalCard from './JournalCard';
import image2_2 from '../../Assets/img/journal2_2.avif'
import image2_1 from '../../Assets/img/journal2_1.jpg'
import image3_1 from '../../Assets/img/journal3_1.jpg'
import image3_2 from '../../Assets/img/journal3_2.avif'

const fakeJournalData = [
    {
        title: "Web Design vs. Web Development",
        description: "What's the Difference and Why It Matters?",
        category: "Website Development",
        year: new Date().getFullYear(),
        img: imagee,
        img2:imagee
    },
    {
        title: "The Future of Web Design",
        description: "Trends to Watch in the Coming Years",
        category: "Design Trends",
        year: new Date().getFullYear(),
        img: image2_1,
        img2:image2_2
    },
    {
        title: "Understanding User Experience",
        description: "Why UX Matters More Than Ever",
        category: "User Experience",
        year: new Date().getFullYear(),
        img: image3_1,
        img2:image3_2
    }
];

const Journal = () => {
    return (
        <div className='journal_main_component'>
            <div className="journal_top_section">
                <p className="journal_heading">Updated Journal</p>
                <p className="journal_desc">We help brands stand out through aweful,
                    elegant visual design. Our design mainly philosophy.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to={'/'} className='focus_explore' style={{ border: '1px solid white' }}>
                        <p className="journal_link" style={{ color: 'white', fontWeight: '600', fontSize: '16px' }}>Read All Journals</p>
                    </Link>
                </div>
            </div>
            <div className="all_journals">
                {
                    fakeJournalData.map((entry, index) => (
                        <JournalCard
                            key={index}
                            title={entry.title}
                            description={entry.description}
                            category={entry.category}
                            year={entry.year}
                            img={entry.img}
                            img2={entry.img2}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default Journal;
