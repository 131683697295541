import { motion, useTransform, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import HSComponent1 from "./SliderComponents/HSComponent1";
import HSComponent2 from "./SliderComponents/HSComponent2";
import HSComponent3 from "./SliderComponents/HSComponent3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Example = () => {
  return (
    <>
      <div className="horizontal_scrollbar">
        <HorizontalScrollCarousel />
      </div>
    </>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const [count, setCount] = useState(0);

  const handleClick = (sign) => {
    if (targetRef.current) {
      targetRef.current.scrollBy({
        left: sign == -1 ? -window.innerWidth : window.innerWidth, // Scroll by 100vw
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <>
      <section
        // ref={targetRef}
        style={{ height: "100vh", overflow: "hidden" }}
        className="horizontal_scroll_behaviour relative h-screen bg-neutral-900"
      >
        <div
          className="sticky top-0 flex h-screen items-center overflow-hidden"
          style={{ width: "" }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "calc(100vw - 90px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              zIndex: "100",
              pointerEvents: "none",
            }}
          >
            <div
              onClick={() => {
                if (count > 0) {
                  handleClick(-1);
                  setCount(count - 1);
                }
              }}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                background: `${count === 0 ? "#7e8489" : "white"}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: `${count === 0 ? "not-allowed" : "pointer"}`,
                marginRight: "auto",
                marginLeft: "20px",
                boxShadow: `2.4px 2.4px 58.3px rgba(0, 0, 0, 0.059),
  5.2px 5.2px 114.7px rgba(0, 0, 0, 0.085),
  9.1px 9.1px 150.8px rgba(0, 0, 0, 0.105),
  15.8px 15.8px 175.5px rgba(0, 0, 0, 0.125),
  30.7px 30.7px 197.5px rgba(0, 0, 0, 0.151),
  100px 100px 219px rgba(0, 0, 0, 0.21)`,
                pointerEvents: "all",
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div
              onClick={() => {
                if (count < 2) {
                  handleClick(1);
                  setCount(count + 1);
                }
              }}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                background: `${count === 2 ? "#7e8489" : "white"}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: `${count === 2 ? "not-allowed" : "pointer"}`,
                boxShadow: `2.4px 2.4px 58.3px rgba(0, 0, 0, 0.059),
  5.2px 5.2px 114.7px rgba(0, 0, 0, 0.085),
  9.1px 9.1px 150.8px rgba(0, 0, 0, 0.105),
  15.8px 15.8px 175.5px rgba(0, 0, 0, 0.125),
  30.7px 30.7px 197.5px rgba(0, 0, 0, 0.151),
  100px 100px 219px rgba(0, 0, 0, 0.21)`,
                pointerEvents: "all",
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
          <div
            style={{ display: "flex", overflow: "auto", width: "100vw" }}
            ref={targetRef}
            className="flex gap-4"
          >
            <HSComponent1 />
            <HSComponent3 />
            <HSComponent2 />
          </div>
        </div>
      </section>
      <div className="mobile_horizon" style={{ display: "none" }}>
        <HSComponent1 />
        <HSComponent3 />
        <HSComponent2 />
      </div>
    </>
  );
};

export default Example;
