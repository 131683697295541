import { Link } from "react-router-dom";
import React, { useState } from "react";
import img1 from "../../Assets/img/explore1.png";
import img2 from "../../Assets/img/explore2.png";

const Focus = () => {
  const [flag, setFlag] = useState(false);
  return (
    <div className="main_focus_div">
      <div className="focus_div_top_content">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "20px",
          }}
        >
          <h1 className="focus_div_top_heading">
            We do not strive to become the biggest company. Rather we aim to
            become the best.
          </h1>
        </div>
      </div>
      <div className="focus_img_div">
        <img
          className="focus_img_div_img"
          src={img2}
          style={{ height: "400px", width: "300px" }}
          height={400}
          width={300}
          alt=""
        />
        <img
          src={img1}
          className="focus_img_div_img2"
          style={{ height: "200px", width: "200px" }}
          height={200}
          width={200}
          alt=""
        />
      </div>
      <div className="focus_div_content">
        <p className="focus_div_content_text">
          We are a team of believers, thinkers, and creators. We are team of
          skillful, customer-centric energetic techno-geeks, hungry for the
          latest resourceful technologies in the web and mobile application
          development fields.We are a start-up that puts its energy into
          bringing this vision to life through innovative technology and
          excellence in customer experience.
        </p>
        <div
          className="focus_last_div"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "40%",
            paddingLeft: "140px",
          }}
        >
          <Link
            to={"/about-us"}
            onMouseOver={() => setFlag(true)}
            onMouseLeave={() => setFlag(false)}
            className="focus_explore"
          >
            <p className="" style={{ color: `${flag ? "white" : ""}` }}>
              Explore Us{" "}
            </p>
          </Link>
          {/* <div style={{background:'#000e2e',marginLeft:'auto',height:'80px',width:'80px'}}></div> */}
        </div>
      </div>
    </div>
  );
};

export default Focus;
