import React from "react";
import { clientsLogo } from "../../constants/logo";
import { Link } from "react-router-dom";

const Clients = () => {
  return (
    <div
      style={{
        background: "aliceblue",
        maxHeight: "fit-content",
        position: "relative",
        zIndex: "2",
        left: "69px",
        padding: "5% 5%",
        paddingBottom: "0",
      }}
      className="clients_div"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <div style={{ background: "blue", height: "40px", width: "4px" }}></div>
        <div>
          <p style={{ lineHeight: "normal", margin: "0", fontSize: "36px" }}>
            Clients and Partners
          </p>
          <p style={{ color: "gray", fontWeight: "500", fontSize: "20px" }}>
            Some of the world's most aggresive and ambitious companies seeks us
            to build there websites and app.
          </p>
        </div>
      </div>
      <div className="scrolling-container">
        <div className="logos">
          {[...clientsLogo, ...clientsLogo].map((e, i) => (
            <Link
              to={e.imgWeb}
              key={i}
              style={{ border: "none", height: "120px" }}
            >
              <img
                className="client-logo"
                src={e.imgUrl}
                alt=""
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
