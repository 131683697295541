export const reviews = [
    {
      name: "Ankit Mehta",
      location: "Mumbai",
      review:
        "The team was fantastic to work with. They understood our vision and executed it flawlessly. Highly recommend their services!",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
    {
      name: "J Laxman Patil",
      location: "Bengaluru",
      review:
        "Professional and dedicated team. They delivered the project on time and exceeded our expectations.",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
    {
      name: "Juain M",
      location: "Scotland",
      review:
        "Great experience! Their expertise in mobile development helped us launch a successful app.",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
    {
      name: "Kapil Patidar",
      location: "Mumbai",
      review:
        "They provided excellent support and were always available to answer our questions. Very satisfied with their work.",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
    {
      name: "Emily Davis",
      location: "New Zealand",
      review:
        "Top-notch service! They were able to turn our ideas into a reality with their skilled development team.",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
    {
      name: "David Wilson",
      location: "Chennai",
      review:
        "Their digital marketing strategies significantly boosted our online presence and sales. Highly effective team.",
      imageUrl: "https://img.freepik.com/free-photo/businesspeople-working-finance-accounting-analyze-financi_74952-1399.jpg",
    },
  ];