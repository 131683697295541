import React, { useState } from 'react'
import './css/home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import FullBar from './FullBar'
import  {Link} from 'react-router-dom'

const SideNav = ({ setFlag }) => {

  return (
    <div className='sideNav_main_div' style={{ zIndex: '9', position: 'fixed' }}>
      <div className="logo_fullbar" style={{ position: 'relative', left: '3%', bottom: '20px' }} >
        <Link to={'/'} style={{ fontSize: '42px', marginLeft: 'auto',transform:'rotate(180deg)',position:'relative',top:'25px' }} >TRISANCO</Link>
      </div>
      <div style={{marginBottom:'15px',display:'flex',flexDirection:'column',cursor:'pointer',marginLeft:'auto'}} onClick={()=>setFlag(true)}>
        <span style={{margin:'0',fontSize:'20px',fontWeight:'600',height:'12px'}}>{'. . .'}</span>
        <span style={{margin:'0',fontSize:'20px',fontWeight:'600',height:'12px'}}>{'. . .'}</span>
        <span style={{margin:'0',fontSize:'20px',fontWeight:'600',height:'12px'}}>{'. . .'}</span>
      </div>

      <div className="contact_sidenav" style={{marginLeft:'auto',transform:'rotate(180deg)',position:'relative',right:'30px'}}>
        <p className="contact_text_side" style={{fontSize:'14px',color:'#555',margin:'0'}}>Contact Us</p>
        <p className="phone_text_side" style={{fontSize:'18px',margin:'0',marginTop:'5px'}}>+91 07554506621</p>
      </div>

    </div>
  )
}

export default SideNav
