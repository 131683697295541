import mycallbook from '../Assets/img/mycallbookmain.jpeg';
import my_money from '../Assets/img/my_money.png';
import smartdocs from '../Assets/img/logo.28eb855c787c04600083.png';
import demandey from '../Assets/img/demandey.jpeg';
import altis from '../Assets/img/altspng-removebg-preview-removebg-preview 2.png';
import lavinLabs from '../Assets/img/lavin_labs_logo.png'

export const clientsLogo = [
    {
        imgUrl: mycallbook,
        title: 'MyCallBook',
        content: 'Connecting you with the world',
        imgWeb: ''
    },
    {
        imgUrl: my_money,
        title: 'My Money',
        content: 'Manage your finances with ease',
        imgWeb: ''
},
    {
        imgUrl: smartdocs,
        title: 'SmartDocs',
        content: 'Smart documentation for smart businesses',
        imgWeb: ''
    },
    // {
    //     imgUrl: demandey,
    //     title: 'Demandey',
    //     content: 'Demand-driven solutions',
    //     imgWeb: ''
    // },
    // {
    //     imgUrl: altis,
    //     title: 'Altis',
    //     content: 'Elevating your business',
    //     imgWeb: ''
    // },
    {
        imgUrl: lavinLabs,
        title: 'Lavin Labs',
        content: 'Elevating your business',
        imgWeb: ''
    }
];
