import React from 'react'

const HSComponent1 = () => {
    return (
        <div className='scrollbar1_first'>
            <p className="scrollbar1_first_text">
                WHY 
            </p>
            <p className="scrollbar1_first_text">
                CHOOSE US 
            </p>
        </div>
    )
}

export default HSComponent1
