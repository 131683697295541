import React from 'react';
import mobileDev from '../../../Assets/img/mobileDev.avif';
import webdev from '../../../Assets/img/webdev.avif';
import uiux from '../../../Assets/img/uiux.avif';
import digital from '../../../Assets/img/digital_marketing.avif';
import ecom from '../../../Assets/img/ecommm.avif';
import cloud from '../../../Assets/img/cloud.avif';

const Services = () => {
  const services = [
    {
      title: 'Mobile Development',
      description: 'We offer cutting-edge mobile development services to create seamless and engaging applications for both iOS and Android platforms.',
      img: mobileDev
    },
    {
      title: 'Web Development',
      description: 'Our team excels in creating responsive, user-friendly, and secure websites that help you establish a strong online presence.',
      img: webdev
    },
    {
      title: 'UI/UX Design',
      description: 'Our UI/UX design services ensure that your application is not only functional but also provides a great user experience.',
      img: uiux
    },
    {
      title: 'Digital Marketing',
      description: 'We provide comprehensive digital marketing strategies to enhance your brand visibility and reach a wider audience.',
      img: digital
    },
    {
      title: 'E-commerce Solutions',
      description: 'We develop robust e-commerce solutions that offer a seamless shopping experience and drive sales for your business.',
      img: ecom
    },
    {
      title: 'Cloud Services',
      description: 'Our cloud services include migration, integration, and management to ensure your business operations are smooth and scalable.',
      img: cloud
    }
  ];

  return (
    <div className='main_services_div'>
      <p style={{ fontSize: '28px', fontWeight: '600', color: 'white', textAlign: 'center' }}>Services We Offer</p>
      <div className='services_list'>
        {services.map((service, i) => (
          <div key={i} className='service_card'>
            <img src={service.img} alt={service.title} />
            <p className="service_card_title">{service.title}</p>
            <p className='service_card_desc'>
              {service.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
