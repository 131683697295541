import React from 'react'
import imgg from '../../../Assets/img/solution.png'
const WhySmall = ({left,right,text,heading}) => {
    return (
        <div className='why_small_main' style={{marginLeft:left,marginRight:right}}>
            <div className="why_small_circle">
                <img height={200} width={200} src={imgg} style={{borderRadius:'50%'}} alt="" className="why_small_img" /> 
            </div>
            <div className="why_small_content">
                <p className="why_small_title">{heading}</p>
                <p className="why_small_desc">{text}</p>
            </div>
        </div>
    )
}

export default WhySmall
