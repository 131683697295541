import React from 'react';

const Marquee = () => {
  return (
    <div className="marquee-container" style={{ marginLeft: '70px' }}>
      <div className="marquee-content">
        {/* Your content goes here */}
        <span>Web Development</span>
        <span>Mobile App Development</span>
        <span>Software Design</span>
        <span>Software Development</span>
        <span>Flutter Development</span>
        <span>Android App Development</span>
        <span>Ios Development</span>
      </div>
    </div>
  );
};

export default Marquee;
