import ComingSoon from "../../Components/ComingSoon/ComingSoon";
import Cursor from "../../Components/GSAP/Cursor";
import Achievements from "../../Components/Home/Achievements";
// import Clients from "../../Components/Home/Clients";
import Focus from "../../Components/Home/Focus";
import HorizontalSlider from "../../Components/Home/HorizontalSlider";
import Journal from "../../Components/Home/Journal";
import Marquee from "../../Components/Home/Marqee";
import MobileNav from "../../Components/Home/MobileNav";
import SideNav from "../../Components/Home/SideNav";
import TopSection from "../../Components/Home/TopSection";
import { lightTheme } from "../../constants/theme";

import React, { useEffect, useState } from "react";
import SmoothScrollableSection from "./Horizontal";
import Clients from "./Clients";
import ClientsSays from "./ClientsSays";
import { reviews } from "../../constants/data";

const Home = () => {
  const [flag, setFlag] = useState(false);
  const [theme, setTheme] = useState(lightTheme);
  function scrollToSection() {
    const section = document.getElementById("mainnnn");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  function scrollToSection1() {
    const section = document.getElementById("mainnnn");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    scrollToSection();
    scrollToSection1();
  }, []);
  const toggleTheme = () => {
    setTheme(theme === lightTheme ? lightTheme : lightTheme);
  };
  return (
    <div className="bg-green bg-blend-darken" style={{ overflow: "hidden" }}>
      <div id="mainnnn"></div>
      <TopSection flag={flag} setFlag={setFlag} />
      <Marquee />
      <Focus />
      <HorizontalSlider />
      {/* <SmoothScrollableSection/> */}
      <Clients />

      <Achievements />
      <div
        className="client_says"
        style={{
          position: "relative",
          zIndex: "2",
          display: "flex",
          background: "white",
          gap: "20px",
          padding: "32px",
          marginLeft: "69px",
          overflowX: "auto",
        }}
      >
        {reviews.map((review, index) => (
          <ClientsSays key={index} {...review} />
        ))}
      </div>
      {/* <ComingSoon text="Clients" /> */}
      <Journal />
    </div>
  );
};

export default Home;
