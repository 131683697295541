import React, { useRef } from 'react';

const JournalCard = ({ title, description, category, year, img,img2 }) => {
    const triggerRef = useRef(null);

    const handleScroll = () => {
        setTimeout(() => {
            triggerRef.current.scrollLeft += 380;
        }, 100);
    };

    const handleLeave = () => {
        setTimeout(() => {
            triggerRef.current.scrollLeft -= 380;
        }, 100);
    };

    return (
        <div className='journalcard_main_component'>
            <div
                className='trigger'
                style={{
                    width: '380px',
                    height: '450px',
                    display: 'flex',
                    overflowX: 'auto',
                    scrollBehavior: 'smooth',
                    transition: 'scrollLeft 0.4s ease',
                }}
                ref={triggerRef}
                onMouseEnter={handleScroll}
                onMouseLeave={handleLeave}
            > 
                <img src={img} height={450} width={400} style={{ maxWidth: "80vw" }} className='journalcard_img' alt={title} />
                <img src={img2} height={450} width={400} style={{ maxWidth: "80vw" }} className='journalcard_img2' alt={title} />
            </div>
            <p className="journalcard_desc">{category} . {year}</p>
            <p className="journalcard_caption">{title}: {description}</p>
        </div>
    );
};

export default JournalCard;
